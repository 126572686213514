import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import styles from './login.module.scss'

// import Link from 'next/link'
import Image from 'next/image'
// import CloseButton from 'react-bootstrap/CloseButton';
// import RegisterModal from './RegisterModal'
// import ResetPassword from './ResetPassword'
import { loadUser, loadAccess, login, loginOTP } from '../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import OtpInput from 'react-otp-input'
import dynamic from 'next/dynamic'
// const AlertModal = dynamic(() => import('./AlertModal'))
import axios from 'axios'
import { APILoginSendOtp, APIVerifyOtp, APIlogin, APIOTPlogin } from '../../config/API'
import Register from './components/Register'
import ResetPassword from './components/ResetPassword'
// import FacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'

const index = ({ show, handleClose = () => {}, showRegisterModal = false }) => {
  const loginLoading = useSelector((state) => state.auth.loading)

  const [showAlert, setAlert] = useState(false)
  const [alertColor, seAlertColor] = useState('success')
  const [alertHeader, setAlertHeader] = useState('Successfully submitted')
  const [alertBody, setAlertBody] = useState('Your query has been successfully submitted')
  // const [alert, setAlert] = useState(false)
  const [validated, setValidated] = useState(false)
  const [type, setType] = useState(1)
  const [OTP, setOTP] = useState('')
  const [showVerifyOTP, setShowVerifyOTP] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)
  const [counter, setCounter] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [isVerified, setisVerified] = useState(false)
  // const [isVerifying, setisVerifying] = useState(false)
  const [otpRequested, setOtpRequested] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [phone, setPhone] = useState(null)

  const [showRegister, setShowRegister] = useState(false)
  const handleCloseRegister = () => setShowRegister(false)
  const handleShowRegister = () => {
    setShowRegister(true)
    // handleClose()
  }
  useEffect(() => {
    showRegisterModal === true && handleShowRegister()
  }, [showRegisterModal])
  const [show2, setShow2] = useState(false)
  const handleClose2 = () => {
    setOtpRequested(false)
    setShow2(false)
  }

  const handleShow2 = () => setShow2(true)
  const [showForgotPwd, setForgotPwd] = useState(false)
  const handleCloseForgetPwd = () => setForgotPwd(false)
  const handleShowforgetPwd = () => {
    setForgotPwd(true)
    // handleClose()
  }

  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    // console.log("Alert clicked",showAlert)
    if (showAlert) {
      // console.log('setting timeout')
      setTimeout(() => {
        setAlert(false)
      }, 5000)
    }
  }, [showAlert])
  useEffect(() => {
    // console.log("counter is", counter)
    if (counter === 0) {
      // console.log("it is zeo")
      setShowResendButton(true)
    }
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])
  const onSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    setLoading(true)
    {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const res = await axios.post(
        APIlogin,
        {
          EMAIL: email,
          PASSWORD: password,
        },
        config
      )
      // console.log(res)
      if (res.data.status) {
        dispatch(login(email, password))
        if (alert.length === 0) {
          handleClose()
          setLoading(false)
        }
      } else {
        setAlert(true)
        setErrorText('Invalid Credentials')
      }
      //
    }
    setValidated(true)
  }

  useEffect(() => {
    setErrorText(null)

    if (phone?.length > 2) {
      // console.log(parsePhoneNumber(phone))
      if (parsePhoneNumber(phone)?.country == 'IN') {
        if (phone.length - parsePhoneNumber(phone)?.countryCallingCode.length > 10) {
          setButtonDisabled(false)
        } else setButtonDisabled(true)
      } else {
        if (phone.length - parsePhoneNumber(phone)?.countryCallingCode.length > 8) {
          setButtonDisabled(false)
        } else setButtonDisabled(true)
      }
    } else {
      setButtonDisabled(true)
    }
  }, [phone])

  useEffect(() => {
    const header = document.getElementById('cp-header')
    if (header) {
      header.style.display = 'none'
    }
    const bHeader = document.getElementById('cp-b-header')
    if (bHeader) {
      bHeader.style.display = 'none'
    }
    const footer = document.getElementById('cp-footer')
    if (footer) {
      footer.style.display = 'none'
    }
    const footer2 = document.getElementById('cp-footer-2')
    if (footer2) {
      footer2.style.display = 'none'
    }

    const homeComponents = document.getElementsByClassName('home_collegepass__Yzacw')
    if (homeComponents.length > 0) {
      Array.from(homeComponents).forEach((element) => {
        element.style.display = 'none'
      })
    }

    return () => {
      const header = document.getElementById('cp-header')
      if (header) {
        header.style.display = 'block'
      }
      const bHeader = document.getElementById('cp-b-header')
      if (bHeader) {
        bHeader.style.display = 'block'
      }
      const footer = document.getElementById('cp-footer')
      if (footer) {
        footer.style.display = 'block'
      }
      const footer2 = document.getElementById('cp-footer-2')
      if (footer2) {
        footer2.style.display = 'block'
      }
      const homeComponents = document.getElementsByClassName('home_collegepass__Yzacw')
      if (homeComponents.length > 0) {
        Array.from(homeComponents).forEach((element) => {
          element.style.display = 'block'
        })
      }
    }
  }, [])
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Fragment>
      <Container
        fluid
        // onClick={(e) => {
        //   // e.stopPropagation()
        //   // window.open('/', '_self')
        //   handleClose()
        //   handleCloseForgetPwd()
        //   handleCloseRegister()
        //   console.log('Details:, clicked ooutside of the boxx')
        // }}
      >
        <Row
          className={styles.wrapLogin}
          // onClick={(e) => {
          //   // e.stopPropagation()
          //   // window.open('/', '_self')
          //   handleClose()
          //   handleCloseForgetPwd()
          //   handleCloseRegister()
          //   console.log('Details:, clicked ooutside of the boxx')
          // }}
        >
          {showForgotPwd ? (
            <ResetPassword
              show={showForgotPwd}
              handleClose={handleClose}
              handleCloseForgetPwd={handleCloseForgetPwd}
              handleCloseRegister={handleCloseRegister}
            />
          ) : showRegister ? (
            <Register
              show={showRegister}
              handleClose={handleClose}
              onSubmitRegister={handleCloseRegister}
              handleCloseForgetPwd={handleCloseForgetPwd}
              handleCloseRegister={handleCloseRegister}
            />
          ) : (
            <Col>
              <Container>
                <Row>
                  <Col className={styles.bigImg}>
                    <Row>
                      <Col className="pt-2">
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            handleClose()
                            handleCloseForgetPwd()
                            handleCloseRegister()
                          }}
                        >
                          <Image
                            src="/logo/cp_text_logo.png"
                            alt="Text Logo"
                            width="180"
                            height="52"
                            className={styles.footerLogo}
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Row
              // onClick={(e) => {
              //   e.stopPropagation()
              // }}
              >
                <Col>
                  <Row className={styles.loginSectionWrap}>
                    <Col className={styles.loginSectionWidth}>
                      <Row className={styles.title}>
                        <Col>
                          {/* <i
                            className="fa fa-times"
                            aria-hidden="true"
                            onClick={() => {
                              handleClose()
                              handleCloseForgetPwd()
                              handleCloseRegister()
                            }}
                          ></i> */}
                          <h1>Sign In</h1>
                        </Col>
                      </Row>
                      <Form className="new-signin-form" validated={validated} onSubmit={onSubmit}>
                        <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicEmail">
                          <Form.Control required type="email" name="email" placeholder="Email" onChange={onChange} />
                          <Form.Control.Feedback type="invalid">Enter a valid email</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicPassword">
                          <span className="pass-eye" style={{ display: 'grid', alignItems: 'center' }}>
                            <Form.Control
                              required
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              placeholder="Password"
                              value={password}
                              onChange={onChange}
                              pattern=".{6,}"
                              style={{paddingRight: '40px'}}
                            />
                            {!showPassword ? (
                              <svg
                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-s6jlyw"
                                className="cursor-pointer border-none"
                                width="20px"
                                fill="#ffffff"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityIcon"
                                aria-label="Show password"
                                onClick={() => setShowPassword((prev) => !prev)}
                                style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}}
                              >
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"></path>
                              </svg>
                            ) : (
                              <svg
                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-s6jlyw"
                                className="cursor-pointer border-none"
                                width="20px"
                                fill="#ffffff"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityOffIcon"
                                aria-label=" password"
                                onClick={() => setShowPassword((prev) => !prev)}
                                style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}}
                              >
                                <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7M2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2m4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3z"></path>
                              </svg>
                            )}
                          </span>
                          <Form.Control.Feedback type="invalid">
                            Password should be atleast 6 characters long
                          </Form.Control.Feedback>{' '}
                        </Form.Group>
                        <Row>
                          <Col>
                            <p style={{ color: 'red', textAlign: 'center' }}>{errorText}</p>
                          </Col>
                        </Row>
                        <Button
                          variant="primary"
                          type="submit"
                          className="width-100 new-signin-btnsbmt"
                          // onClick={onSubmit}
                        >
                          Sign In
                        </Button>
                        <p className="text-center pt-3">OR</p>
                        <Button className="width-100 mb-3 new-signin-frgt" onClick={handleShowforgetPwd}>
                          Forgot password?
                        </Button>
                        <p
                          style={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            fontSize: '1rem',
                            fontWeight: '400',
                            letterSpacing: '0.03rem',
                          }}
                        >
                          New to CollegePass?{' '}
                          <span
                            className="cursor-pointer"
                            style={{
                              fontWeight: '600',
                              color: '#ffffff',
                            }}
                            onClick={handleShowRegister}
                          >
                            Sign up now.
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            letterSpacing: '0.03rem',
                            color: '#8c8c8c',
                          }}
                        >
                          By logging in, you agree to our{' '}
                          <a href="https://www.collegepass.org/privacy">Privacy policy</a> and{' '}
                          <a href="https://www.collegepass.org/terms">Terms of service</a>
                        </p>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>

      {/* <ResetPassword show={showForgotPwd} handleClose={handleCloseForgetPwd} /> */}
    </Fragment>
  )
}

export default index
