import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import styles from '../login.module.scss'

// import Link from 'next/link'
import Image from 'next/image'
// import CloseButton from 'react-bootstrap/CloseButton';
// import RegisterModal from './RegisterModal'
// import ResetPassword from './ResetPassword'
import { loadUser, loadAccess, login, loginOTP, registerV2 } from '../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import OtpInput from 'react-otp-input'
import dynamic from 'next/dynamic'
// const AlertModal = dynamic(() => import('./AlertModal'))
import axios from 'axios'
import { APILoginSendOtp, APIVerifyOtp, APIlogin, APIOTPlogin, APIregistercheck } from '../../../config/API'
import { useRouter } from 'next/router'
// import FacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'

const Register = ({ show, handleClose = () => {}, onSubmitRegister, handleCloseForgetPwd, handleCloseRegister }) => {
  const alert = useSelector((state) => state.alert)

  const router = useRouter()

  // Access specific query parameters
  const queryFirstName = router.query.firstName
  const queryEmail = router.query.email

  console.log('Querfirstname', queryFirstName)
  console.log('Querfirstname', queryEmail)
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showLogin, setShowLogin] = useState(false)
  const handleCloseLogin = () => setShowLogin(false)
  const handleShowLogin = () => {
    setShowLogin(true)
    handleClose()
  }

  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
  })

  const [errorText, setErrorText] = useState(null)

  const { email, password, firstName } = formData

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (queryEmail) {
      console.log('Made it here for queryemail', queryEmail)
      setFormData((prev) => {
        return { ...prev, email: email }
      })
    }
    if (queryFirstName) {
      console.log('Made it here for queryName', queryFirstName)

      setFormData((prev) => ({ ...prev, firstName: queryFirstName }))
    }
  }, [queryEmail, queryFirstName])

  const onSubmit = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    setLoading(true)
    if (form.checkValidity() === false) {
      e.stopPropagation()
      setLoading(false)
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      try {
        const res = await axios.post(
          APIregistercheck,
          {
            EMAIL: email,
            PASSWORD: password,
          },
          config
        )
        if (!res.data.status) {
          setErrorText(res.data.message)
        } else {
          console.log('Made it here')
          dispatch(registerV2(email, password, firstName))
          if (!alert) {
            onSubmitRegister()
            setLoading(false)
          } else {
            console.log('Alert!', alert)
          }
        }
      } catch (err) {
        console.log('Error', err)
        setErrorText(err?.response?.data?.message || 'Something went wrong, please try again later')
      }
    }
    setValidated(true)
  }

  const [showPassword, setShowPassword] = useState(false)

  return (
    <Fragment>
      <Container
        fluid
        // onClick={(e) => {
        //   // e.stopPropagation()
        //   // window.open('/', '_self')
        //   handleClose()
        //   handleCloseForgetPwd()
        //   handleCloseRegister()
        //   console.log('Details:, clicked ooutside of the boxx')
        // }}
      >
        <Row
          className={styles.wrapLogin}
          // onClick={(e) => {
          //   // e.stopPropagation()
          //   // window.open('/', '_self')
          //   console.log('Details:, clicked ooutside of the boxx')
          // }}
        >
          <Col>
            <Container>
              <Row>
                <Col className={styles.bigImg}>
                  <Row>
                    <Col className="pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleClose()
                          handleCloseForgetPwd()
                          handleCloseRegister()
                        }}
                      >
                        <Image
                          src="/logo/cp_text_logo.png"
                          alt="Text Logo"
                          width="180"
                          height="52"
                          className={styles.footerLogo}
                        />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Row className={styles.loginSectionWrap}>
              <Col className={styles.loginSectionWidth}>
                <Row className={styles.title}>
                  <Col>
                    {/* <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={() => {
                        handleClose()
                        handleCloseForgetPwd()
                        handleCloseRegister()
                      }}
                    ></i> */}
                    <h1>Create a Account</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.wrapModalContent}>
                    <Form className="new-signin-form" validated={validated} onSubmit={onSubmit}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicEmail">
                            {/* <Form.Label>First name</Form.Label> */}
                            <Form.Control
                              required
                              type="text"
                              value={firstName}
                              placeholder="First name"
                              name="firstName"
                              onChange={onChange}
                            />
                            <Form.Control.Feedback type="invalid">Enter First Name</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicEmail">
                            {/* <Form.Label>Email</Form.Label> */}
                            <Form.Control
                              required
                              type="email"
                              value={email}
                              placeholder="Email"
                              name="email"
                              onChange={onChange}
                            />
                            <Form.Control.Feedback type="invalid">Enter a valid email</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicPassword">
                            {/* <Form.Label>Password</Form.Label> */}
                            <span className="pass-eye" style={{ display: 'grid', alignItems: 'center' }}>
                              <Form.Control
                                required
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={onChange}
                                pattern=".{6,}"
                              />
                              {!showPassword ? (
                                <svg
                                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-s6jlyw"
                                  className="cursor-pointer border-none"
                                  width="20px"
                                  fill="#ffffff"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="VisibilityIcon"
                                  aria-label="Show password"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                  style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}}
                                >
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"></path>
                                </svg>
                              ) : (
                                <svg
                                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-s6jlyw"
                                  className="cursor-pointer border-none"
                                  width="20px"
                                  fill="#ffffff"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="VisibilityOffIcon"
                                  aria-label=" password"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                  style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}}
                                >
                                  <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7M2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2m4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3z"></path>
                                </svg>
                              )}
                            </span>
                            <Form.Control.Feedback type="invalid">
                              Password should be atleast 6 characters long
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{ color: 'red', textAlign: 'center' }}>{errorText}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center mb-2 pb-2">
                          <Button id="signup" className="width-100 new-signin-btnsbmt" type="submit">
                            Create account
                          </Button>
                        </Col>
                      </Row>
                      {/*<Row>
                    <Col className="text-center">
                      <p className={styles.modalPrivacy}>
                        By logging in, you agree to our{' '}
                        <Link href="/privacy">Privacy Policy</Link> and{' '}
                        <Link href="">Terms</Link> of Service
                      </p>
                    </Col>
                  </Row>*/}
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Register
