import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AfterLoginHeader from './AfterLoginHeader'
import BeforeLoginHeader from './BeforeLoginHeader'
import { loadUser } from '../../actions/authActions'
import { useRouter } from 'next/router'
const index = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [currentPath, setCurrentPath] = useState('')
  const auth = useSelector((state) => state.auth)
  useEffect(() => {
    const getCurrentPath = () => {
      // Check if the code is running on the client-side
      if (typeof window !== 'undefined') {
        const { pathname } = window.location
        setCurrentPath(pathname)
      }
    }
    getCurrentPath()
    // !auth.token && dispatch(loadUser())
    dispatch(loadUser())
  }, [router.pathname])
  if (currentPath === '/referral' || currentPath === '/campaigns/ug-advising') { // campaigns/ug-advising
    return null
  }
  if (auth.token === null || auth.token === undefined) {
    return <BeforeLoginHeader />
  }
  return <AfterLoginHeader />
}
export default index
