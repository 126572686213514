import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Link from 'next/link'
import Image from 'next/image'
import styles from './footer.module.scss'
import Script from 'next/script'

const LandingFooter = ({ hideCopyright }) => {
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: 'rgb(22, 21, 23)',
          color: 'rgb(255, 255, 255)',
        }}
      >
        <Container>
          {/* <Row className="landing-footer-top">
            <Col className={styles.ugTopFooter}>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Row className={styles.ugFooterLogoHolo}>
                    <Col>
                      <Image width="45" height="45" src="/holo.png" alt="Holo" className={styles.ugFooterHolo} />
                      <Image
                        src="/logo/cp_text_logo.png"
                        alt="Text Logo"
                        width="150"
                        height="43"
                        className={styles.ugFooterLogo}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Container>
      </Container>
      {!hideCopyright && (
        <Container
          fluid
          style={{
            backgroundColor: 'rgb(22, 21, 23)',
            color: 'rgb(255, 255, 255)',
          }}
        >
          <Container>
            <Row className="landing-footer-bot">
              <Col className={styles.ugBottomFooter}>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                  <Row className={styles.ugFooterLogoHolo}>
                    <Col>
                      {/* <Image width="45" height="45" src="/holo.png" alt="Holo" className={styles.ugFooterHolo} /> */}
                      <Image
                        src="/logo/cp_text_logo.png"
                        alt="Text Logo"
                        width="150"
                        height="43"
                        className={styles.ugFooterLogo}
                      />
                    </Col>
                  </Row>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Row>
                      <Col className={styles.ugCopyRight}>
                        <Row className='pt-2'>
                          <Col className={styles.ugCopyRightR}>
                            <a href="/privacy">Privacy policy</a>
                            <a
                              href="/terms"
                              style={{
                                border: 'none',
                              }}
                            >
                              Terms and conditions
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </Fragment>
  )
}

export default LandingFooter
